<template>
  <div class="building-details py-8">
    <BuildingAppbar />

    <div class="ma-4">
      <ViewingTitle :title="displayName" />
      <DeleteOrRestore
        v-if="hasDeletePermission"
        :model="building"
        name="building"
        redirect="/buildings"
        @deleted="onDelete"
      />

      <div class="d-flex mb-6">
        <v-tabs hide-slider class="building-info-tabs">
          <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">
            {{ item.tab }}
          </v-tab>

          <v-tab-item class="col-sm-12 col-xs-12 mt-6 pa-0">
            <v-divider class="building-details__divider"></v-divider>

            <BuildingForm :building="building" />
          </v-tab-item>
        </v-tabs>
      </div>
    </div>

    <Loading :value="loading" />
  </div>
</template>

<script>
/**
 * ==================================================================================
 * View Building
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import ViewingTitle from '@/components/common/ViewingTitle'
import BuildingForm from '@/views/Home/Buildings/components/BuildingForm'
import BuildingAppbar from '@/views/Home/Buildings/components/BuildingAppbar'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore'
import bus from '@/store/modules/bus'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  name: 'BuildingDetails',

  components: {
    ViewingTitle,
    BuildingAppbar,
    BuildingForm,
    Loading,
    DeleteOrRestore,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
      tabItems: [{ tab: 'Info' }],
    }
  },

  computed: {
    ...mapState({
      building: (state) => state.building.buildingDetails,
      permissions: (state) => state.auth.permissions,
    }),

    displayName() {
      return this.building ? this.building.name : null
    },

    hasDeletePermission() {
      return validatePermissions(
        [PERMISSION.BUILDINGS_DELETE],
        this.permissions
      )
    },
  },

  methods: {
    ...mapActions({
      getBuildingDetails: 'building/getBuildingDetails',
    }),

    ...mapMutations({
      clearBuildingDetails: 'building/clearBuildingDetails',
      removeBuilding: 'filter/removeBuilding',
    }),

    onDelete() {
      bus.$emit('buildingRemoved', this.building)
      this.removeBuilding(this.building)
    },

    async getBuilding() {
      this.loading = true
      await this.getBuildingDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {
    this.getBuilding()
  },

  destroyed() {
    this.clearBuildingDetails()
  },

  watch: {
    $route() {
      this.getBuilding()
    },
  },
}
</script>

<style lang="scss" scoped>
.building-details {
  &__divider {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
