<template>
  <div class="profile-details py-8">
    <ProfileAppbar />

    <v-row class="mx-4">
      <v-col cols="12" class="ma-0 pa-0">
        <ViewingTitle :title="displayName" class="profile-details__title" />
        <DeleteOrRestore
          :model="profile"
          :max-width="500"
          :name="displayNumberUser"
          redirect="/feature-profile"
        />

        <div class="d-flex mb-6">
          <v-tabs hide-slider class="profile-info-tabs">
            <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">
              {{ item.tab }}
            </v-tab>

            <v-tab-item class="col-sm-12 col-xs-12 mt-6 pa-0">
              <v-divider class="profile-details__divider"></v-divider>

              <ProfileForm class="profile-details__form" :profile="profile" />
            </v-tab-item>
          </v-tabs>
        </div>
      </v-col>
    </v-row>

    <Loading :value="loading" />
  </div>
</template>

<script>
/**
 * ==================================================================================
 * View Building
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import ViewingTitle from '@/components/common/ViewingTitle'
import ProfileForm from '@/views/Home/FeatureProfile/components/ProfileForm'
import ProfileAppbar from '@/views/Home/FeatureProfile/components/ProfileAppbar'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore'

export default {
  name: 'ProfileDetails',

  components: {
    ViewingTitle,
    ProfileAppbar,
    ProfileForm,
    Loading,
    DeleteOrRestore,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
      tabItems: [{ tab: 'Info' }],
    }
  },

  computed: {
    ...mapState({
      profile: (state) => state.profile.profileDetails,
    }),

    displayName() {
      return this.profile ? this.profile.name : null
    },

    displayNumberUser() {
      if (this.profile?.users && this.profile?.users.length > 0) {
        return (
          'profile? Currently, it is applied to ' +
          this.profile?.users.length +
          ' users'
        )
      } else {
        return 'profile? Currently, it is applied to 0 user'
      }
    },
  },

  methods: {
    ...mapActions({
      getProfileDetails: 'profile/getProfileDetails',
    }),

    ...mapMutations({
      clearProfileDetails: 'profile/clearProfileDetails',
    }),

    async getProfile() {
      this.loading = true
      await this.getProfileDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {
    this.getProfile()
  },

  destroyed() {
    this.clearProfileDetails()
  },

  watch: {
    $route() {
      this.getProfile()
    },
  },
}
</script>

<style lang="scss" scoped>
.profile-details {
  &__divider {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
